body,
html,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#root {
  filter: saturate(1.15) hue-rotate(345deg);
}

#root * {
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 1s ease 0.3s forwards;
}

canvas:active {
  cursor: grabbing;
}

.annotation {
  transform: translate3d(calc(50%), calc(-50%), 0);
  text-align: left;
  background: #181c20;
  color: #8c92a4;
  padding: 10px 15px;
  border-radius: 5px;
  user-select: none;
  font-family: var(--leva-fonts-mono);
  pointer-events: none;
}

.annotation::before {
  content: '';
  position: absolute;
  top: 20px;
  left: -30px;
  height: 2px;
  width: 30px;
  background: #181c20;
  pointer-events: none;
}